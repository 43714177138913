.about-text {
  font-size: $font-size-3;
  margin-left: auto;
  margin-right: auto;
  max-width: 45.625rem;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-4;
  }
}

.l-about-social {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 20.1875rem;
  text-align: center;
}

.about-social {
  color: $blue-1;
  font-size: $font-size-4;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-5;
  }

  &:hover, &:focus {
    color: $blue-0;
  }
  
  .svg-inline--fa {
    margin-right: .75rem;
  }
}