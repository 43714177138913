// Vendor
@import "~bootstrap/scss/bootstrap";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

// Base
@import "./base/variables";
@import "./base/global";

// Components
@import "./../sass/components/header.scss";
@import "./../sass/components/footer.scss";
@import "./../sass/components/copyright.scss";
@import "./../sass/components/buttons.scss";

// Pages
@import "./../sass/pages/home.scss";
