.header {
    background: url(/static/media/intro-bg.2dffaaba.png) top 0 right 67% no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-top: .25rem;
    position: relative;

    @include media-breakpoint-up(lg) {
        background: url(../../images/intro-bg.png) top center no-repeat;
        background-size: cover;
        min-height: 44.375rem;
        padding-top: 1.5rem;
    }
}

// Navbar
.navbar--home {
    background-color: rgba(32, 32, 32, 0.9);
    font-size: 1.125rem;

    @include media-breakpoint-down(md) {
        padding: 0.15rem 1rem;
    }

    .navbar-nav {
        .nav-link {
            border-bottom: 1px solid $pink-0;
            color: $white;
            font-weight: normal;
            margin: 0 1rem;

            @include media-breakpoint-up(lg) {
                border-bottom: none;
            }

            &:hover, &:focus, &.active {
                color: $blue-1;
            }
        }
    }

    .navbar-toggler {
        border: 1px solid $pink-1;
        padding: 0.1rem 0.25rem;
        transition: .12s;

        &:hover, &:focus {
            background-color: $pink-1;

            .navbar-toggler-icon {
                background-image: url(../../images/menu-mobile--white.svg);
            }
        }
        &:active {
            background-color: darken($pink-1, 5%);
        }
    }

    .navbar-toggler-icon {
        background-image: url(../../images/menu-mobile.svg);
    }

    .navbar-brand {
        img {
            @include media-breakpoint-down(md) {
                max-height: 2.5rem;
            }
        }
    }
}

// Intro
.intro {
    align-items: center;
    display: flex;
    flex: 1;
    font-size: $font-size-3;
    text-align: center;

    @include media-breakpoint-up(lg) {
        align-items: flex-end;
        font-size: $font-size-7;
        padding-bottom: 2rem;
    }
}

.intro__first-text {
    display: block;
    margin-bottom: 1.5rem;
}

.intro__big-text {
    display: block;
    font-size: $font-size-6;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-8;
    }
    @include media-breakpoint-up(xl) {
        font-size: $font-size-9;
    }
}

.intro__pink-text {
    color: $pink-1;
}

.intro__next {
    bottom: 0;
    color: $blue-2;
    display: inline-block;
    font-size: 3rem;
    line-height: 1;
    margin-top: 1.5rem;
    padding: .75rem 1.5rem;
    
    @include media-breakpoint-up(lg) {
        font-size: 3.5rem;
        margin-top: 3rem;
        padding-bottom: .25rem;
    }

    &:hover, &:focus {
        color: lighten($blue-2, 5%);
    }

    &:hover {
        animation-name: slide-bottom;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-delay: 0s;
        animation-iteration-count: 2;
        animation-direction: alternate;
        animation-fill-mode: none;
    }

    @keyframes slide-bottom {
        0%{
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100%{
            -webkit-transform: translateY(20px);
            transform: translateY(20px);
        }
    }
}

.l-intro__button {
    margin-top: 7%;
}
