.footer {
    background: $blue-2 url('../../images/footer-bg-blue--mobile.png') top center no-repeat;
    border-top: 1px solid $blue-3;
    padding-bottom: 2.625rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
        background-image: url('../../images/footer-bg-blue--desktop.png');
        padding-bottom: 5.375rem;
    }

    .l-footer-heading {
        padding-bottom: 2.625rem;
        padding-top: 2.625rem;

        @include media-breakpoint-up(lg) {
            padding-top: 5.9375rem;
        }
    }

    .l-contact {
        font-size: $font-size-3;
        margin-bottom: 2.125rem;

        @include media-breakpoint-up(lg) {
            margin-top: 3.9375rem;
        }
    }

    .l-contact__item {
        margin-top: 35px;
    }

    .contact-title {
        font-size: $font-size-3;
        font-weight: bold;
    }

    .contact-email {
        color: $white;
        text-decoration: underline;
    }

    .l-contact-icon {
        margin-bottom: .75rem;

        .svg-inline--fa {
            color: $yellow-1;
            font-size: $font-size-6;
        }
    }

    .footer-social {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        max-width: 18.5rem;
        text-align: center;
    }

    .footer-social__link {
        align-items: center;
        background-color: $white;
        border: 2px solid transparent;
        border-radius: 100%;
        color: $blue-1;
        display: flex;
        font-size: $font-size-5;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;
        transition: .15s;

        &:hover, &:focus {
            border: 2px solid white;
            color: $white;
            background-color: $blue-1;
        }
    }
}