/* 
    Colors 
*/
/* Gray Scale */
$gray-3: #2B2B2B;
$gray-4: #202020;
$white: #ffffff;

/* Theme */
// Blue
$blue-0: #E1F1F5;
$blue-1: #4E9EB5;
$blue-2: #127B9B;
$blue-3: #035167;

/* Pink */
$pink-0: #F8E7F3;
$pink-1: #D86FB8;

/* Yellow */
$yellow-1: #FFF46E;

/* 
    Font size 
*/
$font-size-1: .875rem; // 14px
$font-size-2: 1rem; // 16px
$font-size-3: 1.125rem; // 18px
$font-size-4: 1.25rem; // 20px
$font-size-5: 1.5rem; // 24px
$font-size-6: 2rem; // 32px
$font-size-7: 3rem; // 48px
$font-size-8: 4rem; // 64px
$font-size-9: 5.5rem; // 88px

/* 
    Buttons 
*/

